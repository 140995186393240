import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../../components/section/policies/PoliciesNavBar';
import PoliciesContentArea from '../../../components/section/policies/PoliciesContentArea';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

const Page = () => (
  <Body header={header}>
    <SEO
      title="Subprocessors - Rotaready"
      url="policies/subprocessors"
    />

    <ResponsiveContainer>
      <ContentWrapper>
        <TitleWrapper>
          <H1 uistyle="brand160" text="Subprocessors" />
        </TitleWrapper>

        <BodyWrapper>
          <PoliciesNavBar />

          <PoliciesContentArea>
            <p><b>Last updated: 1 November 2021</b></p>

            <p>To support delivery of our Services, Rotaready Ltd (or one of its Affiliates listed below) may engage
              and use data processors with access to certain Customer Data (each, a "<b>Subprocessor</b>"). This
              page provides important information about the identity, location and role of each Subprocessor. Terms
              used on this page but not defined have the meaning set forth in the written agreement between
              Customer and Rotaready (the "<b>Agreement</b>").</p>

            <H2 uistyle="brand160">Third Parties</H2>

            <p>Rotaready currently uses third party Subprocessors to provide infrastructure services, and to help us
              provide customer support and notifications. Prior to engaging any third party Subprocessor,
              Rotaready performs diligence to evaluate their privacy, security and confidentiality practices, and
              executes an agreement implementing its applicable obligations.</p>

            <H4 uistyle="brand160">Infrastructure Subprocessors</H4>

            <p>Rotaready may use the following Subprocessors to host Customer Data or provide other infrastructure
              that helps with delivery of our Services:</p>

            <table>
              <thead>
              <tr>
                <th>Entity Name</th>
                <th>Subprocessing Activities</th>
                <th>Entity Country</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Amazon Web Services, Inc.</td>
                <td>Cloud Service Provider</td>
                <td>United States</td>
              </tr>
              </tbody>
            </table>

            <H4 uistyle="brand160">Other Subprocessors</H4>

            <p>Rotaready may use the following Subprocessors to perform other Service functions:</p>

            <table>
              <thead>
              <tr>
                <th>Entity Name</th>
                <th>Subprocessing Activities</th>
                <th>Entity Country</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Intercom, Inc.</td>
                <td>Cloud-based Customer Support Services</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Pusher Ltd.</td>
                <td>Cloud-based Realtime Notification Services</td>
                <td>United Kingdom</td>
              </tr>
              <tr>
                <td>PubNub, Inc.</td>
                <td>Cloud-based Realtime Notification Services</td>
                <td>United States</td>
              </tr>
              </tbody>
            </table>

            <H2 uistyle="brand160">Rotaready Affiliates</H2>

            <p>Depending on the geographic location of a Customer or their Authorised Users, and the nature of the
              Services provided, Rotaready may also engage one or more of the following Affiliates as
              Subprocessors to deliver some or all of the Services provided to a Customer:</p>

            <table>
              <thead>
              <tr>
                <th>Entity Name</th>
                <th>Entity Country</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Rotaready Ltd</td>
                <td>United Kingdom</td>
              </tr>
              </tbody>
            </table>

            <H2 uistyle="brand160">Updates</H2>

            <p>As our business grows and evolves, the Subprocessors we engage may also change. We will endeavor to
              provide the owner of Customer's account with notice of any new Subprocessors to the extent required
              under the Agreement, along with posting such updates here. Please check back frequently for
              updates.</p>
          </PoliciesContentArea>
        </BodyWrapper>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default Page;
