import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1 1 auto;
  
  font-family: ${({ theme: { fonts } }) => fonts.Body1.fontFamily};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: ${({ theme: { colors } }) => colors.grey};
  
  a {
    color: ${({ theme: { colors } }) => colors.brand};
    text-decoration: none;
    
    &:hover {
      color: ${({ theme: { colors } }) => colors.brand160};
    }
  }
  
  table {
    width: 100%;
    margin: 20px 0 40px 0;
    border-collapse: collapse;
    border-spacing: 0;
    
    tr > th {
      text-align: left;
      background-color: ${({ theme: { colors } }) => colors.grey20};
      padding: 15px 10px;
      font-weight: 600;
    }
    
    tr > td {
      border-top: solid 1px ${({ theme: { colors } }) => colors.grey20};
      padding: 10px;
    }
  }
`;

const PoliciesContentArea = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

PoliciesContentArea.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PoliciesContentArea;
