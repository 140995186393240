import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Text from '@rotaready/frecl/build/Text';
import H5 from '@rotaready/frecl/build/H5';

const NavBar = styled.div`
  flex: 0 0 325px;
`;

const NavHeading = styled(H5).attrs({
  uistyle: 'brand160',
})``;

const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkItem = styled.li`
  margin-top: 20px;
  
  &:last-child {
    margin-bottom: 40px;
  }
  
  a, a:hover * {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const PoliciesNavBar = () => (
  <NavBar>
    <NavHeading text="Getting started" />

    <Links>
      <LinkItem><Link to="/policies"><Text text="Overview" /></Link></LinkItem>
    </Links>

    <NavHeading text="Terms" />

    <Links>
      <LinkItem><Link to="/policies/user-terms-of-service"><Text text="User Terms of Service" /></Link></LinkItem>
      <LinkItem><Link to="/policies/data-processing"><Text text="Data Processing Addendum" /></Link></LinkItem>
    </Links>

    <NavHeading text="Policies" />

    <Links>
      <LinkItem><Link to="/policies/privacy-policy"><Text text="Privacy Policy" /></Link></LinkItem>
      <LinkItem><Link to="/policies/acceptable-use-policy"><Text text="Acceptable Use Policy" /></Link></LinkItem>
      <LinkItem><Link to="/policies/subprocessors"><Text text="Subprocessors" /></Link></LinkItem>
      <LinkItem><Link to="/policies/security-privacy-architecture"><Text text="Security, Privacy & Architecture" /></Link></LinkItem>
    </Links>
  </NavBar>
);

export default PoliciesNavBar;
